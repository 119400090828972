<template>
  <div class="blog-list">
    <ul>
      <li v-for="(post, index) in posts" :key="post.id" :class="getBackgroundClass(index)">
        <router-link :to="'/blog/' + post.id">{{ post.title }}</router-link>
        <p>{{ post.date }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'BlogList',
  data() {
    return {
      posts: []
    };
  },
  methods: {
    async fetchPosts() {
      try {
        const response = await fetch('/blog-posts/posts.json');
        const data = await response.json();
        // Sort posts after fetching
        this.posts = data.sort((a, b) => new Date(b.date) - new Date(a.date));
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    },
    getBackgroundClass(index) {
      return index % 2 === 0 ? 'bg-white' : 'bg-light-gray';
    }
  },
  created() {
    this.fetchPosts();
  }
};
</script>

<style scoped>
.blog-list {
  max-width: 800px;
  margin: 2rem auto;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.blog-list h2 {
  margin-bottom: 1rem;
}

.blog-list ul {
  list-style: none;
  padding: 0;
}

.blog-list li {
  padding: 1rem;
  border-bottom: 1px solid #ddd;
}

.blog-list li:last-child {
  border-bottom: none;
}

.blog-list a {
  font-size: 1.2rem;
  text-decoration: none;
  color: #333;
}

.blog-list a:hover {
  text-decoration: underline;
}

.bg-white {
  background: #fff;
}

.bg-light-gray {
  background: #f4f4f4;
}
</style>