<template>
  <section id="experience">
    <h2>Professional Experience</h2>
    <ul>
      <li 
        v-for="(job, index) in experience" 
        :key="job.id" 
        :class="{'even-entry': index % 2 === 0, 'odd-entry': index % 2 !== 0}"
      >
        <h3>{{ job.title }} at 
          <a :href="job.website" target="_blank">{{ job.company }}</a>
        </h3>
        <p>{{ job.period }}</p>
        <div v-html="job.description"></div>
        <h4>Tools Used:</h4>
        <p v-html="formattedTools(job.tools)"></p>
      </li>
    </ul>
  </section>
</template>

<script>
import experience from '../ResumeData.js';

export default {
  name: 'ProfessionalExperience',
  data() {
    return {
      experience
    };
  },
  methods: {
    formattedTools(tools) {
      return tools.join('&nbsp;&nbsp;'); // Join tools with HTML non-breaking spaces
    }
  }
};
</script>

<style scoped>
section {
  padding: 2rem;
  margin: 2rem 0;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 1.5rem;
  padding: 1rem; /* Add padding for better spacing */
  border-radius: 5px; /* Round corners */
}

.even-entry {
  background: #f9f9f9; /* Light gray for even entries */
}

.odd-entry {
  background: #ffffff; /* White for odd entries */
}

h3 {
  margin: 0;
}

h4 {
  margin: 0.5rem 0;
}
</style>
