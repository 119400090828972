const experience = [
    {
      id: 1,
      title: 'Backend Engineer',
      company: 'Exness',
      website: 'https://www.exness.com',
      period: 'Jul 2024 - Present',
      tools: ['Golang'],
      description: 'Contributing to the development of trading products aimed at enhancing the company\'s key business metrics.'
    },
    {
      id: 2,
      title: 'Tech Lead',
      company: 'Tabby',
      website: 'https://tabby.ai/en-AE',
      period: 'Feb 2022 - Jul 2024',
      tools: ['Golang', 'GCP', 'PostgreSQL', 'Redis', 'Kubernetes', 'Pub/Sub'],
      description: `
      <p>During my tenure at Tabby, I progressed from a Backend Engineer to a Tech Lead, 
      contributing significantly to the development and maintenance of various services 
      while collaborating with a highly skilled team. My primary responsibilities included 
      supporting the launch of new products and enhancing the operational aspects of our 
      business, particularly the collection platform, which was integral to our "buy now, 
      pay later" product.</p>

      <p>Initially, I served as the sole backend developer on this project. By the time I 
      left, our team had expanded into a dedicated international unit comprising a stream lead, 
      project manager, team lead, tech lead (myself), three backend developers, two frontend 
      developers, two QA engineers, and one designer.</p>

      <p>Throughout my time at Tabby, the company achieved profitability and began planning 
      for an IPO. We primarily utilized Golang for backend services hosted on Google Cloud 
      Platform (GCP) with Kubernetes, and employed technologies such as PostgreSQL, GCP 
      Pub/Sub, Redis, OpenAPI, React, and Datadog for monitoring key business metrics.</p>
    `
    },
    {
      id: 3,
      title: 'Software Engineer',
      company: 'RetailNext',
      website: 'https://retailnext.net',
      period: 'Feb 2018 - Mar 2022',
      tools: ['Golang', 'GCP', 'AWS', 'ElasticSearch', 'Cassandra', 'Kafka', 'Pub/Sub', 'TypeScript'],
      description: `
      <p>At RetailNext, I joined a dedicated team focused on developing an internal tool designed to optimize business operations by consolidating various processes into a unified platform. This initiative posed challenges as it required the integration of both online and offline operations, including the installation of physical sensors, product RMAs, and numerous other workflows.</p>

      <p>Under the leadership of the company's CTO, our team of six engineers successfully delivered a robust product that generated significant cost savings for the organization.</p>

      <p>Initially, we built our services on AWS, later transitioning to Google Cloud Platform (GCP). We utilized JSON API for backend and frontend communication, with Golang as our primary backend programming language.</p>`
    },
    {
      id: 4,
      title: 'Python Developer',
      company: 'Auriga',
      website: 'https://auriga.com',
      period: 'Jun 2017 - Mar 2018',
      tools: ['Python', 'MS SQL'],
      description: `
      <p>At Auriga, I contributed to a project focused on extracting and analyzing data from an existing database to explore the potential application of machine learning algorithms.</p>

      <p>I designed and developed an intuitive interactive visualization tool that retrieves data from the database, utilizing Pandas for data filtering and allowing users to establish custom rules and threshold values. Additionally, I integrated dynamic statistical features, including distribution histograms and key metrics such as mean, median, and standard deviation.</p>

      <p>Furthermore, I implemented a user alerting system, enabling users to configure personalized notifications. This system sends automated email alerts to designated users when new data entries in the database meet user-defined criteria.</p>`
    },
    {
      id: 5,
      title: 'Python Developer',
      company: 'Artezio',
      website: 'https://www.artezio.com/',
      period: 'Sep 2016 - Jun 2017',
      tools: ['Python', 'scraping tools', 'SQL', 'JavaScript', 'XML', 'Ajax', 'HTML'],
      description: `
      <p>At Artezio, I focused on building integrations between a large meta parser website and its clients, facilitating user access to affordable air ticket searches. I successfully integrated multiple partner APIs into the customer service framework, enhancing the overall functionality and user experience.</p>

      <p>Additionally, I contributed to the development of new tools for monitoring these integrations, ensuring seamless operation and reliability. I also played a key role in designing efficient workflows for engineering teams, optimizing processes to enhance productivity and collaboration.</p>`
    },
    {
      id: 6,
      title: 'QA Engineer',
      company: 'Orion Innovation (ex Mera)',
      website: 'https://www.orioninc.com/',
      period: 'Jan 2016 - Sep 2016',
      tools: ['Python', 'VoIP', 'Jira', 'TestComplete'],
      description: `
      <p>At Orion Innovation, my primary responsibility was to implement regression testing and develop new test cases for an existing Java application, while also testing a new Python/JavaScript application during its development phase.</p>

      <p>I established numerous test environments utilizing medical equipment integrated with the software, ensuring comprehensive evaluation and validation of functionality. Additionally, I developed basic automated tests using the TestComplete tool, streamlining the testing process and enhancing overall software reliability.</p>`
    }
  ];
  
  export default experience;
  