<template>
  <section id="about-me">
    <h2>About Me</h2>
    <p>
      I am Ivan Rozhkov, an enthusiastic engineer with a strong focus on creating effective and scalable solutions across diverse projects. With a background in backend development and experience in various technologies, I am dedicated to understanding product needs and delivering results that align with business goals.
    </p>
    <p>
      I believe in the power of collaboration and am eager to partner on innovative projects, bringing a flexible mindset and a commitment to excellence. Whether it’s optimizing existing systems, integrating new technologies, or exploring data-driven insights, I am ready to contribute and support the success of any product.
    </p>
    <p>
      Currently based in Cyprus, I am open to global opportunities and excited to connect with potential partners and clients from around the world.
    </p>
  </section>
</template>

<script>
export default {
  name: 'AboutMe'
};
</script>

<style scoped>
#about-me {
  margin-left: 20px;
  margin-right: 20px;
}
</style>
