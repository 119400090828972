<template>
  <div id="app">
    <header>
      <h1>Ivan Rozhkov</h1>
      <nav>
        <router-link to="/">Blog</router-link>
        <router-link to="/about">About Me</router-link>
        <router-link to="/experience">Professional Experience</router-link>
        <router-link to="/contact">Contact</router-link>
      </nav>
    </header>
    <router-view />
    <FooterComponent />
  </div>
</template>

<script>
import FooterComponent from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    FooterComponent
  }
};
</script>

<style>
/* Common styles */
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  background: #f4f4f4;
}

header {
  background: #333;
  color: #fff;
  padding: 1rem 0;
  text-align: center;
}

header nav a {
  color: #fff;
  margin: 0 1rem;
  text-decoration: none;
}

header nav a.router-link-exact-active {
  font-weight: bold;
}

/* Ensure footer does not overlap content */
#app {
  padding-bottom: 60px;
}
</style>