<template>
  <footer id="footer">
    <p>
      Connect with me on
      <a href="https://www.linkedin.com/in/ivan-rozhkov-3769ba111/" target="_blank" rel="noopener noreferrer">
        <font-awesome-icon :icon="['fab', 'linkedin']" /> LinkedIn
      </a>
      &nbsp;or&nbsp;
      <a href="https://t.me/ivanrozhkov" target="_blank" rel="noopener noreferrer">
        <font-awesome-icon :icon="['fab', 'telegram']" /> Telegram
      </a>
    </p>
  </footer>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLinkedin, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faLinkedin, faTelegram);

export default {
  name: 'FooterComponent',
  components: {
    FontAwesomeIcon
  }
};
</script>

<style scoped>
#footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  bottom: 0;
}

#footer a {
  color: #3498db;
  text-decoration: none;
}

#footer a:hover {
  text-decoration: underline;
}

#footer p {
  margin: 0;
}
</style>
